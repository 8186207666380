import React from 'react'
import './Me.css'

function Me() {
  return (
    <> 
    <p className="me"><a href="lucianbrooks.com">~Website By Lucian Brooks~</a></p>
    </>
  )
}

export default Me