import React from 'react'
import Espanol from './Espanol'
import './Footer.css'
import Me from './Me'

function Footer() {
  return (
    <div className='footer-container'>
      <Espanol/>
      <h3 className='footer-h3'>AT YOUR SERVICE 24 HOURS A DAY 7 DAYS A WEEK</h3>
      <div className="footer-content-container">
        <img src="https://cdn.website.thryv.com/-resellers-preview/_widget_builder_files/visa.png" alt="" />
        <img src="https://cdn.website.thryv.com/-resellers-preview/_widget_builder_files/mastercard.png" alt="" />
        <img src="https://cdn.website.thryv.com/-resellers-preview/_widget_builder_files/cash.png" alt="" />
      </div>
      <Me />
    </div>
  )
}

export default Footer