import React from 'react'
import { FaRegCheckSquare } from 'react-icons/fa'
import CallButton from './CallButton'
import './Checklist.css'
import Espanol from './Espanol'

function Checklist() {
  return (
    <div className='checklist-container'>
      <ul>
        <li><FaRegCheckSquare /> 24 Hour Service</li>
        <li><FaRegCheckSquare /> Flatbed Truck</li>
        <li><FaRegCheckSquare /> From Olympia to Marysville</li>
        <li><FaRegCheckSquare /> Winch Out</li>
        <li><FaRegCheckSquare /> Lock Out Service</li>   
        <li><Espanol/></li>   
      </ul>
      <CallButton />
    </div>
  )
}

export default Checklist