import React from 'react'
import CallButton from './CallButton'
import Checklist from './Checklist'
import Espanol from './Espanol'
import NavBar from './NavBar'
import './Welcome.css'

function Welcome() {
  return (
    <div className='welcome-container'>
      <NavBar />
      <div className="welcome-content-container">
        <h1>TRUSTED TOWING</h1>
        <h2>AUTO TRANSPORT AND ASSISTANCE</h2>
        <Checklist />
        {/* <CallButton /> */}
      </div>
    </div>

  )
}

export default Welcome